.listview {
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
    clear: both;
    margin-top: 0.5em;
    padding: 0;
    box-sizing: border-box;
}

.listview .list-item {
    height: 200px;
    border-radius: 5px;
    border: 1px solid #10835e;
    background: mintcream;
    box-shadow: 1px 1px #10835e;
    overflow: hidden;
    padding: 0.5em;
    box-sizing: border-box;
    outline: 0;
}

.listview .active-item {
    cursor: pointer;
    transition: opacity 0.5s ease-in;
}

.listview .active-item:hover, .listview .list-item:focus {
    opacity: 0.75;
    box-shadow: 2px 2px 5px #10835e;
}

.listview .actions-right {
    display: flex;
    flex-direction: column;
}

.listview .list-form-item {
    height: auto;
}

.listview-wrapper .actions-right {
    margin-bottom: 0.5em;
}

.list-form-item fieldset {
    width: 50%;
}


@media only screen and (max-width: 1000px) {

    .listview {
        grid-template-columns: 1fr;
    }
}
