
.form-field {
    margin-bottom: 1.5em;
    width: 100%;
}


.form-field .input {
    width: 100%;
    outline: none;
    border: 0.1em solid  #1b9791;
    box-shadow: 0.1em 0.1em #1b9791;
    border-radius: 0.4em;
    padding: 0.2em;
    transition: border 0.5s, box-shadow 0.5s;
}

.form-field .input:focus {
    box-shadow: 0.15em 0.15em #1b9791;
    border-color: #1b9791;
}

.form legend {
    margin: 0 0 0.5em;
    padding-bottom: 0.2em;
    border-width: 0 0 0.1em;
    border-style: solid;
    width: 100%;
    font-size: 14px;
    border-color: #1b9791;
    font-size: 1.2em;
}

.form fieldset {
    border: none;
}

.form-field .value-description {
    font-size: 0.9em;
    font-style: italic;
    margin-top: 0.3em;
}

.form-field .error {
    font-size: 0.9em;
    font-style: italic;
    color: red;
    position: absolute;
    margin-top: 0.2em;
    display: block;
}

.form-field .form-label {
    display: block;
}

.form {
    color: #424242;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.summary-error {
    color: red;
}

.summary {
    margin: 1em 0;
}

.upload-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 0.5em;
}

.upload-preview {
    max-height: 100px;
}