
.loading path {
    stroke: #10835e;
    fill: #10835e;
}

.loading {
    background: url(../../../assets/loading.gif) 50% 50% no-repeat lightgrey;
    opacity: 0.2;
    width: 100%;
    height: 100%;
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
}
