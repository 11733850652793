.button {
    height: 2em;
    border-radius: 0.5em;
    border: 0.1em solid #10835e;
    background: #0f7d5a;
    color: white;
    box-shadow: 0.1em 0.1em 0.3em #10835e;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    gap: 0.2em;
    margin: 0.2em;
    padding: 0 0.5em;
    cursor: pointer;
    transition: opacity 0.5s;
    outline: 0;
}

.button .icon, .icon-link .icon, .navigation-link .icon {
    width: 1em;
    vertical-align: middle;
    margin-right: 0.1em;
}

.navigation-link {
    color: #10835e;
    outline: 0;
}

.navigation-link.icon-right .icon {
    margin-left: 0.5em;
}

.navigation-link:focus {
    color: #10835e;
}

.navigation-link .icon path, .navigation-link .icon line, .navigation-link .icon polygon {
    fill: #10835e;
    stroke: #10835e;
}

.icon-link {
    outline: 0;
}

.icon-link .icon {
    width: 2em;
    margin-left: 1em;
}

.button.disabled {
    opacity: 0.6;
    pointer-events: none;
}

.button:hover:not(.disabled), .button:focus {
    opacity: 0.8;
    box-shadow: 0.2em 0.2em 0.4em #10835e;
}

.icon path, .icon line, .icon polygon {
    fill: white;
    stroke: white;
}

.actions-right {
    float: right;
}