
.menu {
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    gap: 5px;
  }

.menu a:hover, .menu a:focus {
    outline: 0;
}

.menu a {
    white-space: nowrap;
}

.menu li {
  display: inline-flex;
}

.menu li.hidden {
  display: none;
}

.menu .menu-overflow-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: auto;
}

.menu-overflow-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  transition: 0.5s transform;
}

.menu-overflow-button:hover {
  transform: scale(1.1);
}

.menu-overflow-button svg {
  height: 40px;
  width: 40px;
}

.menu-overflow-button path {
  fill: transparent;
  stroke: #fff;
}

.menu-popup-wrapper {
  position: relative;
  width: 100%;
}

.menu-popup {
  position: absolute;
  top: 0;
  right: 0;
  text-align: right;
  background: #1C4231
}

.menu-popup ul {
  padding: 0;
  list-style: none;
}

.menu-popup li, .menu-popup a {
  width: 100%;
  text-align: center;
}