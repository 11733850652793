
.day-range, .time-range {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.work-hours {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    justify-content: center;
}

.work-hours .command-row {
    align-self: flex-end;
}

.work-hours-list {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    row-gap: 1em;
    column-gap: 1em;
}

.work-hour-times {
    grid-column: 2/3;
}