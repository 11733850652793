.people {
    display: flex;
    flex-direction: column;
    gap: 1em;
    list-style: none;
    clear: both;
}

.people li {
    width: 70%;
    outline: 0;
}

.people li:focus .person-info {
    box-shadow: 0px 2px #10835e;
}

.person-description {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.person-info {
    border-bottom: 1px solid #1b9791;
    padding-bottom: 1em;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr 2fr;
    column-gap: 1em;
    clear: both;
}

.person-info img {
    max-width: 100%;
    max-height: 300px;
    grid-row: 1/3;
    margin: auto 0;
    pointer-events: none;
}

.person-info .person-title-column {
    text-align: center;
}

.person-title-column h2, .person-title-column h3  {
    margin: 0 0 10px 0;
}

.person-info p {
    margin: 0;
}

.person-right {
    align-self: flex-end;
}

.person-form-list {
    grid-template-columns: 1fr 2fr;
}

.person-form-list fieldset {
    width: 80%;
}

@media only screen and (max-width: 800px) {

    .person-info {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }

    .person-info .person-title-column {
        grid-row: 1/2;
    }

    .person-info img {
        grid-row: 2/3;
        margin: 0 auto;
    }

}

@media only screen and (max-width: 1100px) {
    .about-content {
        width: 100%;
    }

    .person-right {
        align-self: flex-start;
    }

    .people li {
        width: 100%;
    }

    .person-form-list {
        grid-template-columns: 1fr;
    }

    .person-form-list fieldset {
        width: 50%;
    }
}