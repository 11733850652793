.notification-container {
    position: fixed;
    z-index: 1000;
    top: 1em;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    align-items: center;
    width: 50%;
    left: 25%;
}

.notification {
    border-radius: 0.4em;
    padding: 1em;
    color: white;
    text-align: center;
    width: 15em;
}

.error-notification {
    background: #d51923;
}

.success-notification {
    background: #00d168;
}

@keyframes remove-animation {
    from { opacity: 1;}
    to {opacity: 0;}
}
  
.remove-animation {
    animation-name: remove-animation;
    animation-duration: 1s;
}

@keyframes show-animation {
    from { opacity: 0;}
    to {opacity: 1;}
}

.show-animation {
    animation-name: show-animation;
    animation-duration: 1s;
}