
.tabstrip {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tabstrip-header {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
}

.header-item {
    padding: 0.5em;
    cursor: pointer;
    border-radius: 0.1em;
    color: #1b9791;
}

.header-item:hover {
    box-shadow: 0px 3px 5px hsl(177, 72%, 35%, 0.5);
}

.header-item.selected {
    box-shadow: 0px 3px hsl(177, 72%, 35%, 0.5);
}

.header-item.selected:hover {
    box-shadow: 0px 7px 5px hsl(177, 72%, 35%, 0.5);
}