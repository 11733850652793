
.case-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin: 0 auto;
}

.case-detail-images {
    display: flex;
    flex-direction: row;
    gap: 2em;
    align-items: center;
}

.case-detail-image {
    max-height: 300px;
    max-width: 50%;
}

.case-item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;
}

.case-item p {
    margin: 0;
    flex-grow: 1;
    overflow: hidden;
    line-height: 1.2em;
    text-overflow: ellipsis;
    width: 100%;
}

.case-description .secondary-title {
    margin: 0;
}

.case-item .case-image {
    height: 100%;
    margin-right: 10px;
    max-width: 33%;
}

.case-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1;
    overflow: hidden;
    text-overflow: ellipsis;
    gap: 0.1em;
}

@media only screen and (max-width: 800px) {

    .case-details {
        width: 100%;
    }
}
