

.content-wrapper {
    padding: 2em;
    flex: 1;
}

body {
    margin: 0;
}

.header {
    background-image: linear-gradient(45deg, #1C4231, #139A6F);
    top: 0;
    position: sticky;
    z-index: 100;
}

footer {
    background: #2f7053;
    color: white;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

.footer-column {
    display: inline-flex;
    flex-direction: column;
    flex-basis: 33%;
    gap: 1em;
    padding: 1em;
    box-sizing: border-box;
}

.footer-middle {
    align-self: center;
    align-items: center;
}

.footer-work-hours .work-hours {
    align-items: center;
}
.footer-work-hours .work-hours-list {
    row-gap: 0.1em;
}

.footer-work-hours {
    align-items: end;
}

.content {
    display:flex;
    flex-direction:column;
    min-height:100vh;
    max-width: 100vw;
    background: #fafefc;
    min-width: 200px;
}

.header .actions-right {
    top: 0.5em;
    right: 0.5em;
    position: absolute;
}

.logo {
    margin: 1em;
    width: 100px;
    height: 100px;
}

.logo-container {
    flex-basis: 15%;
}

.header nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 10px;
}

.navigation {
    flex-grow: 1;
    min-width: 0;
}

.navigation a {
    padding: 1em;
    text-decoration: none;
    color: white;
    border-radius: 5px;
    font-size: 1.1em;
    transition: background 0.7s, box-shadow 0.5s;
}

.navigation a.active-link {
    color: #d9ed92;
}

.navigation a:hover, .navigation a:focus {
    color: white;
    box-shadow: 1px 1px 10px #d9ed92;
    outline: 0;
}

.navigation a:hover {
    background: #1c4231;
}

.navigation .menu-popup {
    border-radius: 2px;
    background: hsl(161, 59%, 26%);
}

.navigation .menu-popup li {
    box-shadow: 0 1px hsl(161, 79%, 35%);
}

@media only screen and (max-width: 800px) {
    .content-wrapper  {
        padding: 1em;
    }

    .footer-column {
        flex-basis: 50%;
        align-content: center;
        flex-wrap: wrap;
    }

    .footer-middle {
        flex-basis: 100%;
        order: 2;
    }
}
