.home-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin: 0 auto;
}

.paragraph {
    text-indent: 2em;
}

.home-form form {
    width: 70%;
    margin: 0 auto;
}

.home-form fieldset {
    width: 100%;
}

@media only screen and (max-width: 800px) {
    .home-content {
        width: 100%;
    }

    .home-form form {
        width: 100%;
    }
}