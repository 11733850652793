.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(210, 210, 210, 0.3);
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup {
    border-radius: 5px;
    background: white;
    opacity: 1;
    display: flex;
    flex-direction: column;
    min-width: 200px;
}

.popup-content {
    padding: 1em;
}

.popup-top-actions {
    background: #10835e;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.popup-top-actions .button {
    background: none;
    border-width: 0;
    box-shadow: none;
}

.popup-top-actions button.button:hover {
    box-shadow: none;
    background-color: #13aa7a;
}

.popup-bottom-actions {
    padding: 0 1em 0.5em;
}