.contact {
    display:flex;
    flex-direction: row;
    justify-content: center;
    clear: both;
    margin-top: 0.5em;
}

.contact-wrapper .actions-right {
    margin-bottom: 0.5em;
}

.address {
    display:flex;
    flex-direction: column;
    padding: 1em;
    width: 50%;
}

.address h4, .address h5 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1em;
}

.map-container {
    height: 300px;
    width: 50%;
    z-index: 1;
    flex-shrink: 1;
}

@media only screen and (min-width: 1000px) {
    .address {
        width: 30%;
    }

    .contact-wrapper .page-title {
        margin: auto 10%;
    }
}